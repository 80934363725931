<template>
  <div class="main-box" v-if="shopDetail" @click.stop="closemodel">
    <a-breadcrumb>
      <a-breadcrumb-item
        ><router-link to="/knowledgeMall/shopHome"
          >书课专区</router-link
        ></a-breadcrumb-item
      >
      <a-breadcrumb-item v-if="$route.query.name"
        ><a @click="$router.go(-1)">{{
          $route.query.name
        }}</a></a-breadcrumb-item
      >
      <a-breadcrumb-item style="color: #15b7dd">{{
        $route.query.productId ? shopDetail.name : shopDetail.packageName
      }}</a-breadcrumb-item>
    </a-breadcrumb>
    <div class="content">
      <div class="shopimg">
        <img class="img" :src="imgSrc" alt="" />
        <!-- 商品 -->
        <!-- <div class="imgList" v-if="$route.query.productId && shopDetail.photoList && shopDetail.photoList.length > 1">
          <img :class="{active:imgSrc == item.photo}" :src="item.photo" v-for="(item,index) in shopDetail.photoList" @click="imgSrc = item.photo" :key="index" alt="">
        </div> -->
      </div>
      <div class="shopDetail">
        <p class="name" v-if="$route.query.productId">{{ shopDetail.name }}</p>
        <!-- <p class="describe" v-if="$route.query.productId && shopDetail.type == 4">{{shopDetail.answerNumber}}</p> -->
        <div class="shopMessage">
          <div class="price">
            <p class="title">价格：</p>
            <!-- 商品 -->
            <p
              class="new"
              :class="{'newFree': !shopDetail.specialPrice}"
              v-if="$route.query.productId && !!shopDetail.specialPrice"
            >
              ￥<span>{{ managePrice(shopDetail.specialPrice)[0] }}<span class="new_point">.{{managePrice(shopDetail.specialPrice)[1]}}</span></span>
            </p>
            <p
              class="new"
              :class="{'newFree': !shopDetail.specialPrice}"
              v-if="$route.query.productId && !shopDetail.specialPrice"
            >
              免费
            </p>
            <p class="old" v-if="$route.query.productId && shopDetail.isMarket">
              ￥{{ (shopDetail.marketPrice * 1).toFixed(2) }}
            </p>
          </div>
          <div class="navs" v-if="couponList && couponList.length && shopDetail.specialPrice">
            <p class="title">优惠券：</p>
            <p
              class="nav"
              v-for="(ite, ind) in couponList"
              :key="ind"
              v-show="ind < 4"
            >
              {{ite.type == 1?('满'+ite.fullPrice+'减'+ite.couponPrice):ite.type == 3?('满'+ite.discountPrice+(ite.discountType==1?'件':'元')+ite.discount+'折'):''}}
            </p>
            <p
              class="button"
              @click.stop="couponFlag = !couponFlag"
            >
              领券<a-icon class="icon" type="down" v-if="!couponFlag" /><a-icon class="icon" v-else type="up" />
            </p>
            <div class="couponList" v-show="couponFlag">
              <div class="coupon" :class="{geted:ite.drawId}" v-for="(ite, ind) in couponList" :key="ind">
                <div class="couponLeft">
                  <!-- 满减券 -->
                  <p class="price" v-if="ite.type == 1">￥<span
                    :style="[
                      {
                        fontSize:(ite.couponPrice+'').length < 4?'30px':(ite.couponPrice+'').length == 4?'24px':'18px'
                      },
                      {
                        lineHeight:(ite.couponPrice+'').length <= 4?'45px':'37px'
                      }
                    ]
                    ">{{ite.couponPrice}}</span></p>
                  <!-- 折扣券 -->
                  <p class="price" v-else><span>{{ite.discount}}</span>折</p>
                  <p class="condition">{{ite.type == 1?('满'+ite.fullPrice):ite.type == 3?('满'+ite.discountPrice+(ite.discountType==1?'件':'元')):''}}可用</p>
                </div>
                <div class="couponRight">
                  <p class="couponName mallHidden">{{ite.name}}</p>
                  <p class="useTime">{{useTime(ite)}}</p>
                  <p class="scope mallHidden">【{{ite.receiveDesc}}】</p>
                </div>
                <div v-if="!ite.drawId" @click="receiveCoupon(ite)" class="couponBtn">领取</div>
                <div v-else class="couponStyle">已领取</div>
              </div>
            </div>
          </div>
        </div>
        <p
          v-if="$route.query.productId"
          class="introduce"
          v-html="shopDetail.webDesc"
        ></p>
        <p v-if="$route.query.productId" class="menu">
          <img src="@/assets/image/knowledgeMall/examIcon1.png" alt="" />
          <span>答题次数：</span
          >{{
            shopDetail.isExam ? shopDetail.examNum + "次考试次数" : "无限次"
          }}
        </p>
        <p v-if="$route.query.productId" class="menu">
          <img src="@/assets/image/knowledgeMall/examIcon2.png" alt="" />
          <span>考试时长：</span>{{ manageDuration(shopDetail.duration) }}
        </p>
        <div class="buyBtns">
          <div
            v-if="!shopDetail.isBuy && shopDetail.specialPrice"
            class="buybutton"
            @click="joinShopCar"
          >
            <a-icon type="shopping-cart" />
            <p>加入购物车</p>
          </div>
          <div
            v-if="!shopDetail.isBuy && shopDetail.specialPrice"
            class="buybutton"
            @click="buyNow"
          >
            <a-icon type="shopping" />
            <p>立即购买</p>
          </div>
          <div
            v-if="shopDetail.isBuy || !shopDetail.specialPrice"
            class="buybutton"
            @click="goExam"
          >
            <p>去考试</p>
          </div>
          <!-- <button v-if="shopDetail.isBuy" @click="goExam()">去考试</button> -->
        </div>
      </div>
    </div>
    <!-- tabs -->
    <div class="tabs-box">
      <!-- 切换 -->
      <div class="tabs">
        <a :class="shopDetail.webGoodsDesc?tabIndex==1?'tabsdiv a1':'tabsdiv':''" v-if="$route.query.productId && shopDetail.webGoodsDesc" href="javascript:;" @click="tabIndex=1">
          <span>详情介绍</span>
        </a>

        <a :class="!shopDetail.webGoodsDesc?tabIndex==2?'a2 tabsdiv':'tabsdiv':tabIndex==2?'a2 tabsdiv':'tabsdiv'" v-if="shopDetail.problemList && shopDetail.problemList.length" href="javascript:;" @click="tabIndex=2">
          <span>常见问题</span>
        </a>
        
      </div>
      <!-- 视图 -->
      <div class="contenttab">
        <!-- 详情介绍 -->
        <div v-if="tabIndex == 1" v-html="shopDetail.webGoodsDesc"></div>
        <!-- 常见问题 -->
        <div v-else-if="tabIndex == 2">
          <div
            class="questionItem"
            v-for="(item, index) in shopDetail.problemList"
            :key="index"
          >
            <div class="imgTitle">
              <img src="@/assets/image/knowledgeMall/question.png" alt="" />
              <p class="name">{{ item.name }}</p>
            </div>
            <p class="answer">{{ item.context }}</p>
          </div>
        </div>
      </div>
    </div>
    <Shopwarning :visible="visible" :fontText="fontText" />
    <Sidebar ref="sidebar"/>
    <!-- 免费模拟考试去考试弹框 -->
    <TipsModal 
      :isCancelBtnShow="true"
      ConfirmBtnText="去购买"
      :isPublicTipsShow="isPublicTipsShow" 
      :isConfirmLoading="false"
      :tipsText="goExamText"  
      @setEvent="goCourseDetail" />
  </div>
</template>

<script>
// import forTree from '@/components/forTree/index.vue'
import Sidebar from "@/components/sidebar.vue";
export default {
  // 可用组件的哈希表
  components: { Sidebar },
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      couponFlag: false, // 优惠券弹框状态
      classList: [], // 商品-班期list
      visible: false,
      fontText: "", // 文本
      tabIndex: 1,
      formData: {},
      mallClass: false, // 更多期数状态
      imgSrc: require("@/assets/image/temporary/pic_nav.jpg"),
      tabMenu: ["全部", "213"],
      couponList: null, // 优惠券列表
      swiperOptions: {
        slidesPerView: 4,
        centeredSlides: true,
        centeredSlidesBounds: true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
          disabledClass: "my-button-disabled",
        },
        // Some Swiper option/callback...
      },
      // 包含课程列表
      courseList: [],
      // 图书教具列表
      productList: [],
      // 商品推荐列表
      goodShopList: [],
      // 套餐列表
      setmealList: [],
      shopDetail: null,
      isPublicTipsShow: false, // 去考试限制弹框
			goExamText: '', // 去考试限制提示文案
    };
  },
  // 事件处理器
  methods: {
    closemodel(){
      if(this.couponFlag) {
        this.couponFlag = false;
      }
    },
    useTime(ite) {
      if(ite.times == 2) {
        if(ite.startTime && ite.lapseTime) {
          return ite.startTime.split(' ')[0] + '-' + ite.lapseTime.split(' ')[0]
        }
      }else if(ite.times == 3) {
        if(ite.lapseTime) {
          return '至'+ ite.lapseTime.split(' ')[0]
        }
        if(ite.days) {
          return '领取后'+ite.days+'天内可用'
        }
      }else if(ite.times == 1) {
        return '不限'
      }
    },
    // 跳转课程详情
		goCourseDetail(){
			this.$router.push('/knowledgeMall/courseDetail?productId='
      + this.$AES.encode_data(this.goproductId + "") +
        "&code=" + this.goproductCode)
		},
    // 模拟考试购买校验
    async verification() {
      let res = await this.$ajax({
        url: '/hxclass-pc/exam/mock/config/mall-purchase-verifi',
        method: 'get',
        params: {
          configId: this.shopDetail.configId
        }
      })
      return res;
    },

    // 判断商品是否拥有（加购/已购）
    async isUsed() {
      let res = await this.$ajax({
        url:'/hxclass-pc/pc-mall/app/shop/use',
        method:"get",
        params:{
          openTimeId:'',
          productId:this.shopDetail.productArchivesId,
          type:4, // 商品类型1.课程 2.图书 3.教具 4.模拟考试 5.电子照片 6.延期 7.补考 8.直播	
        }
      })
      return res;
    },

    // 加购接口
    async shopSave() {
      let res = await this.$ajax({
        url:'/hxclass-pc/pc-mall/shop/save',
        method:'post',
        params:{
          count:1, // 数量
          couponCode:'', // 优惠劵编码
          openTimeId:'', // 开班设置id
          price:this.shopDetail.marketPrice, // 价格  优惠价格-还没有计算优惠券
          productId:this.shopDetail.productArchivesId, // 商品id
          type:4, // 商品类型1.课程 2.图书 3.教具 4.模拟考试 5.电子照片 6.延期 7.补考
          userNo:this.$store.state.userInfo.userId, // 用户
        }
      })
      return res;
    },

    // 加入购物车
    async joinShopCar() {
      if(!this.$store.state.userInfo.userId){
				return this.$router.push('/login/loginIndex?type=2');
			}
			if(this.shopDetail.isBuy){
				return this.$message('该考试已购买，不可重复购买');
			}
      // 是否可购买
      let res = await this.verification()
      if(res.code == 200 && res.success){
        // 添加购物车
        let shopSave = await this.shopSave();
        if(shopSave.code == 200 && shopSave.success){
          this.$refs['sidebar'].getShopList()
          return this.$message.success('添加购物车成功');
        }else{
          this.$message.error(shopSave.message)
        }
        // 是否已加购或已购买
        // let isUsed = await this.isUsed();
        // if(isUsed.code == 200){
        //   return this.$message.success('该考试已加购，不可重复购买');
        // }else{
        //   // 添加购物车
        //   let shopSave = await this.shopSave();
        //   if(shopSave.code == 200 && shopSave.success){
        //     this.$refs['sidebar'].getShopList()
        //     return this.$message.success('添加购物车成功');
        //   }else{
        //     this.$message.error(shopSave.message)
        //   }
        // }
      }else {
        this.$message.error(res.message);
      }
    },

    // 立即购买
    async buyNow() {
      if(!this.$store.state.userInfo.userId){
				return this.$router.push('/login/loginIndex?type=2');
			}
      // 是否可购买
      let res = await this.verification()
      if(res.code == 200 && res.success){
        if(this.shopDetail.isBuy){
          return this.$message('该考试已购买，不可重复购买');
        }
        let list = [];
        list.push({
          productId:this.shopDetail.productArchivesId, // 商品id
          count:1, // 数量")
          openTimeId:'', // 班级id
          type:4, // 商品类型 1.课程 2.图书 3.教具 4.模拟考试 5.电子照片 6.延期 7.补考 8.直播 9.套餐
          province:'', // 省
          city:'',// 市
          noGift:0, // 是否为赠品 0否1是t
          productList:[]
        })
        this.$store.commit('updatelist',list);
        this.$store.commit('updateType',1);
        this.$store.commit('updateShortInvoiceId',null);
        this.$store.commit('updateInvoiceId',null);
        this.$store.commit('updateAddress',null);
        this.$store.commit('updateCouponList',null);
        this.$router.push('/order/confirmOrder')
      }else {
        this.$message.error(res.message);
      }
    },

    // 去考试
    async goExam(){
      if(!this.$store.state.userInfo.userId){
				return this.$router.push('/login/loginIndex?type=2');
			}
			if(!this.shopDetail.specialPrice){
				let res = await this.$ajax({
					url: '/hxclass-pc/exam/mock/config/go-free-exam/verifi',
					method: 'get',
					params: {
						userId: this.$store.state.userInfo.userId,
						configId: this.shopDetail.configId
					}
				})
				if(res.code == 200 && res.success){

				}else{
					this.goExamText = res.message;
          this.goproductCode = res.data.productCode;
          this.goproductId = res.data.productId;
					this.isPublicTipsShow = true;
					return
				}
			}
      // 
      this.$router.push(
        '/knowledgeMall/examSystem?paperId=' +
          this.$AES.encode_data(this.shopDetail.paperId + '') +
          '&configId=' +
          this.$AES.encode_data(this.shopDetail.configId + '')
      )
      

      const examForm = {
        duration: this.shopDetail.duration,
        startTime: new Date(),
        stemJson: {},
      }
      localStorage.setItem('examForm', JSON.stringify(examForm))
    },
    // 考试时长处理
		manageDuration(con) {
			let time = ''
			let hour = con.split(':')[0]
			let minute = con.split(':')[1]
			let second = con.split(':')[2]
			if (hour !== '00') {
				if (hour[0] == '0') {
					time += hour.substring(1, hour.length)+'小时'
				} else {
					time += hour+'小时'
				}
			}
			if (minute !== '00') {
				time += minute+'分钟'
			}
			if (second !== '00') {
				time += second+'秒'
			}
			return time
		},
    // 价格处理
    managePrice(con) {
      let txt = (con * 1).toFixed(2).split('.')
      return txt
    },
    // 获取商品详情
    getShopDetail() {
      this.$ajax({
        url: "/hxclass-pc/exam/mock/config/product-get",
        method: "get",
        params: {
          userId: this.$store.state.userInfo.userId,
          productId: this.$AES.decode_data(this.$route.query.productId),
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          if(!res.data){
            return this.$message.error('该模拟考试已下架')
          }
          this.shopDetail = res.data;
          if (this.shopDetail.webGoodsDesc) {
            this.tabIndex = 1;
          } else {
            this.tabIndex = 2;
          }
          this.imgSrc = res.data.appPicture;
        } else {
          this.$message.error(res.message);
        }
      });
    },

    selectTime(){
      //时间戳
      // let time = +new Date();
      //时间戳
      let time = new Date().getTime();
      var date = new Date(time + 8*3600*1000); // 增加8小时
      return date.toJSON().substr(0, 19).replace('T', ' ');
    },

    // 领取优惠券
    receiveCoupon(e) {
      if(!this.$store.state.userInfo.userId){
        return this.$router.push('/login/loginIndex?type=2')
      }
      if(this.receiveCouponflag){
        return 
      }
      this.receiveCouponflag = true;
      this.$ajax({
        url:'/hxclass-pc/pc-mall/app/save-draw',
        method:'post',
        params:{
          couponId:e.couponId
        }
      }).then(res=>{
        this.receiveCouponflag = false;
        if(res.code == 200 && res.success){
          this.$message.success('领取成功');
          this.getCouponList();
        }else{
          this.$message.error(res.message);
        }
      })

      // 旧版
      // this.$ajax({
      //   url:'/hxclass-pc/pc-mall/shop/coupon-receive',
      //   method:'post',
      //   params:{
      //     couponId:e.couponId,
      //     receiver:this.$store.state.userInfo.userId,
      //     receiverTime:this.selectTime(),
      //     state:1
      //   }
      // }).then(res=>{
      //   this.receiveCouponflag = false;
      //   if(res.code == 200 && res.success){
      //     this.$message.success('领取成功');
      //     this.getCouponList();
      //   }else{
      //     this.$message.error(res.message);
      //   }
      // })
    },

    // 获取优惠券列表
    getCouponList(e) {
      this.$ajax({
        url: "/hxclass-pc/pc-mall/product/coupon",
        method: "get",
        params: {
          productId: this.$AES.decode_data(this.$route.query.productId + ""), //	购物车商品 1,2,3,4
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.couponList = res.data;
        } else {
          this.$message.error(res.message);
        }
      });
      
      // 旧版优惠券列表
      // this.$ajax({
      //   url: "/hxclass-pc/pc-mall/shop/coupon-list",
      //   method: "get",
      //   params: {
      //     productId: this.$AES.decode_data(this.$route.query.productId + ""), //	购物车商品 1,2,3,4
      //     type: e, // 1商品 2套餐
      //     productType: 4,
      //   },
      // }).then((res) => {
      //   if (res.code == 200 && res.success) {
      //     this.couponList = res.data;
      //   } else {
      //     this.$message.error(res.message);
      //   }
      // });
    },
  },
  // 生命周期-实例创建完成后调用
  created() {
    if (this.$route.query.productId) {
      this.getShopDetail();
      // 获取优惠券列表
      this.getCouponList(1);
    }
  },
  // 生命周期-实例挂载后调用
  mounted() {},
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
// 常见问题列表
.questionItem {
  padding: 0 60px;
  margin-bottom: 80px;
  .imgTitle {
    display: flex;
    align-items: center;
    img {
      width: 24px;
      height: 21px;
    }
    .name {
      margin-left: 8px;
      font-size: 16px;
      font-family: PingFangMedium;
      font-weight: bold;
      color: #333333;
      line-height: 24px;
    }
  }
  .answer {
    margin-top: 16px;
    padding-left: 32px;
    font-size: 16px;
    font-family: PingFangMedium;
    color: #333333;
    line-height: 24px;
  }
}
.questionItem:last-child {
  margin-bottom: 0;
}
// 图书教具走马灯
.carouselList {
  display: flex !important;
  justify-content: center;
  align-items: center;
  height: 108px;
  .item {
    width: 310px;
    height: 108px;
    background: #ffffff;
    border-radius: 5px;
    opacity: 1;
    border: 1px solid #ebebeb;
    margin-right: 10px;
    padding: 24px;
    display: flex;
    position: relative;
    .img {
      width: 60px;
      height: 60px;
    }
    .checked {
      width: 16px;
      height: 16px;
      position: absolute;
      left: 4px;
      top: 50px;
      cursor: pointer;
    }
    .right {
      margin-left: 16px;
      flex: 1;
      .produceName {
        width: 182px;
        font-size: 14px;
        font-family: PingFangMedium;
        color: #333333;
        line-height: 21px;
      }
      .bottom {
        margin-top: 15px;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        .price {
          font-size: 14px;
          font-family: PingFangMedium;
          color: #EC6C01;
          line-height: 21px;
        }
        .count {
          width: 108px;
          height: 24px;
          display: flex;
          align-items: center;
          padding: 0 6px;
          border-radius: 2px;
          border: 1px solid #ebebeb;
          .icon {
            font-size: 14px;
            color: #666666;
          }
          ::v-deep.ant-input-number-handler-wrap {
            display: none;
          }
          ::v-deep.ant-input-number {
            height: 20px;
            width: 77px;
            border: 0;
          }
          ::v-deep.ant-input-number-input {
            height: 20px;
            text-align: center;
          }
          ::v-deep.ant-input-number:focus {
            box-shadow: none !important;
          }
        }
      }
    }
  }
  .item:last-child {
    margin: 0;
  }
}
.ant-carousel {
  width: 707px;
  height: 108px;
  .slick-slide {
    text-align: center;
    height: 160px;
    line-height: 160px;
    background: #364d79;
    overflow: hidden;
  }
  .custom-slick-arrow {
    width: 34px;
    height: 108px;
    font-size: 25px;
    color: #999999;
    background: #f5f5f5;
    border-radius: 5px;
    display: flex !important;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .custom-slick-arrow:before {
    display: none;
  }
  // .custom-slick-arrow:hover {
  //   opacity: 0.5;
  // }
  .slick-slide h3 {
    color: #fff;
  }
}
.setmealList {
  background-color: #ffffff;
  padding: 40px 0;
  .steps {
    display: block;
    margin: 10px auto;
    width: 820px;
    height: 10px;
  }
  .stepsfour {
    display: block;
    margin: 10px auto;
    width: 1025px;
    height: 10px;
  }
  .shopMessage {
    display: flex;
    justify-content: center;
    align-items: center;
    .title {
      font-size: 25px;
      font-family: PingFangMedium;
      color: #333333;
      line-height: 25px;
      letter-spacing: 2px;
      margin-right: 25px;
    }
    .price {
      height: 58px;
      text-align: center;
      padding-top: 10px;
      .new {
        font-size: 18px;
        font-family: PingFangMedium;
        color: #EC6C01;
        line-height: 18px;
        span {
          font-size: 31px;
        }
      }
      .old {
        margin-top: 2px;
        font-size: 16px;
        font-family: PingFangMedium;
        color: #666666;
        line-height: 19px;
      }
    }
    .warning {
      font-size: 16px;
      font-family: PingFangMedium;
      color: #999999;
      line-height: 19px;
      margin-left: 29px;
    }
    .button {
      cursor: pointer;
      background: #15b7dd;
      box-shadow: 0px 5px 5px 1px rgba(206, 206, 206, 0.25);
      border-radius: 26px 26px 26px 26px;
      padding: 4px 36px;
      margin-left: 21px;
      font-size: 23px;
      font-family: PingFangMedium;
      color: #ffffff;
      line-height: 34px;
      .icon {
        color: #ffffff;
        font-size: 23px;
        margin-right: 6px;
      }
    }
  }
}
.setmealshopList {
  display: flex;
  justify-content: center;
  .industryitem {
    padding: 0 29px;
  }
}
.industryitem {
  position: relative;
  z-index: 3;
  .itemcontent {
    text-align: center;
    img {
      width: 257px;
      height: 224px;
      border-radius: 5px 5px 0px 0px;
    }
    .setmealcontent {
      width: 257px;
      height: 114px;
      margin: 0 auto;
      text-align: left;
      padding: 12px 10px 21px;
      transition: all 0.5s;
      margin-bottom: 10px;
      box-shadow: 0px 5px 5px 1px rgba(93, 131, 167, 0.1);
      .name {
        font-size: 18px;
        font-family: PingFangMedium;
        color: #000000;
        line-height: 28px;
      }
      .nav {
        display: flex;
        align-items: center;
        margin-top: 7px;
        .label {
          font-size: 14px;
          font-family: PingFangMedium;
          color: #1e97b4;
          line-height: 21px;
        }
        .oldPrice {
          font-size: 14px;
          font-family: PingFangMedium;
          text-decoration: line-through;
          color: #999999;
          line-height: 21px;
          margin-right: 9px;
        }
        .navs {
          font-size: 12px;
          font-family: PingFangMedium;
          color: #EC6C01;
          line-height: 18px;
          border-radius: 4px;
          border: 1px solid #EC6C01;
          padding: 2px 8px;
          font-weight: 400;
          margin-right: 5px;
        }
      }
      .newPrice {
        font-size: 12px;
        font-family: PingFangMedium;
        font-weight: bold;
        color: #EC6C01;
        line-height: 18px;
        margin-top: 11px;
        span {
          font-size: 18px;
        }
      }
    }
  }
  .warning {
    font-size: 16px;
    font-family: PingFangMedium;
    color: #999999;
    line-height: 19px;
  }
  .connector {
    position: absolute;
    right: -12.5px;
    top: 109px;
    .icon {
      width: 25px;
      height: 25px;
      font-size: 25px;
      color: #15b7dd;
    }
  }
}
.rightArrow {
  position: absolute;
  left: -12.5px;
  top: 109px;
  width: 25px;
  height: 25px;
}
.buyBox {
  text-align: center;
  width: 257px;
  padding-top: 74px;
  .title {
    font-size: 25px;
    font-family: PingFangMedium;
    color: #333333;
    line-height: 25px;
    letter-spacing: 2px;
    margin-bottom: 41px;
  }
  .price {
    height: 58px;
    text-align: center;
    .new {
      font-size: 18px;
      font-family: PingFangMedium;
      color: #EC6C01;
      line-height: 18px;
      span {
        font-size: 31px;
      }
    }
    .old {
      margin-top: 2px;
      font-size: 16px;
      font-family: PingFangMedium;
      color: #666666;
      line-height: 19px;
    }
  }
  .button {
    cursor: pointer;
    background: #15b7dd;
    box-shadow: 0px 5px 5px 1px rgba(206, 206, 206, 0.25);
    border-radius: 26px 26px 26px 26px;
    padding: 4px 36px;
    font-size: 23px;
    font-family: PingFangMedium;
    color: #ffffff;
    line-height: 34px;
    .icon {
      color: #ffffff;
      font-size: 23px;
      margin-right: 6px;
    }
  }
}
::v-deep.swiper-container {
  // margin: 0;
  width: 100%;
}
::v-deep.swiper-button-next {
  position: absolute;
  right: 0px;
  top: 187px;
  width: 40px;
  height: 40px;
  background-image: url("~@/assets/image/knowledgeMall/circularright.png");
  background-repeat: no-repeat;
  background-size: 40px;
  // img {
  //   width: 40px;
  //   height: 40px;
  //   position: relative;
  //   border-bottom-left-radius: 22px;
  //   border-top-left-radius: 22px;
  // }
}
.swiper-button-next:hover {
  background-image: url("~@/assets/image/knowledgeMall/circularright2.png");
}
::v-deep.swiper-button-next:after {
  opacity: 0;
}
::v-deep.swiper-button-prev:after {
  opacity: 0;
}
::v-deep.swiper-button-prev {
  position: absolute;
  left: 0;
  top: 187px;
  width: 40px;
  height: 40px;
  background-image: url("~@/assets/image/knowledgeMall/circularleft.png");
  background-repeat: no-repeat;
  background-size: 40px;
  // img {
  //   width: 40px;
  //   height: 40px;
  //   position: relative;
  //   border-bottom-left-radius: 22px;
  //   border-top-left-radius: 22px;
  // }
}
.swiper-button-prev:hover {
  background-image: url("~@/assets/image/knowledgeMall/circularleft2.png");
}
.industryitem {
  position: relative;
  .shopItem {
    text-align: center;
    .name {
      width: 300px;
      margin: 0 auto;
      text-align: left;
    }
    .oldPrice {
      width: 300px;
      margin: 0 auto;
      text-align: left;
    }
  }
}
.main-box {
  padding-top: 80px;
  padding-bottom: 60px;
  .banner {
    background: #fafafa;
    border-radius: 4px;
    padding: 40px 24px;
    // display: flex;
    .item {
      display: flex;
      .icon {
        margin: 77px 30px 0;
      }
    }
    .shopItem {
      .img {
        width: 300px;
        height: 260px;
      }
      .shopintroduce {
        width: 300px;
        height: 133px;
        margin: 0 auto;
        border-radius: 0 0 5px 5px;
        box-sizing: border-box;
        border: 1px solid #15b7dd;
        padding: 12px 24px 20px;
        .name {
          font-size: 18px;
          font-family: PingFangMedium;
          color: #333333;
          line-height: 27px;
        }
        .introduce {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 7px;
          .label {
            font-size: 14px;
            font-family: PingFangMedium;
            color: #1e97b4;
            line-height: 21px;
          }
          .navs {
            font-size: 12px;
            font-family: PingFangMedium;
            color: #EC6C01;
            line-height: 18px;
            border-radius: 4px;
            border: 1px solid #EC6C01;
            padding: 2px 8px;
            font-weight: 400;
          }
        }
        .price {
          display: flex;
          align-items: center;
          margin-top: 16px;
          .new {
            font-size: 14px;
            font-family: PingFangMedium;
            color: #EC6C01;
            line-height: 21px;
            span {
              font-size: 20px;
            }
          }
          .old {
            font-size: 14px;
            font-family: PingFangMedium;
            text-decoration: line-through;
            color: #999999;
            line-height: 21px;
            margin-left: 9px;
          }
        }
      }
    }
  }
  .moduletitle {
    padding: 10px 40px;
    margin-top: 40px;
    font-size: 24px;
    font-family: PingFangMedium;
    font-weight: bold;
    color: #15b7dd;
    line-height: 28px;
    display: flex;
    align-items: center;
    background: #f8f8f8;
    span {
      background-color: #15b7dd;
      height: 19px;
      width: 4px;
      border-radius: 7px;
      margin-right: 12px;
    }
  }
  .content {
    margin-top: 24px;
    padding: 40px;
    border-radius: 5px;
    background: #ffffff;
    display: flex;
    .shopimg {
      .img {
        width: 496px;
        height: 496px;
        border-radius: 5px;
      }
      @media screen and (max-width:16000px) {
        .img {
          width: 426px;
          height: 426px;
        }
      }
      .imgList {
        display: flex;
        margin-top: 12px;
        .active {
          border: 1px solid #15b7dd;
        }
        img {
          width: 60px;
          height: 60px;
          margin-right: 16px;
          border-radius: 2px;
          box-sizing: border-box;
          cursor: pointer;
        }
        img:last-child {
          margin: 0;
        }
      }
    }
    .shopDetail {
      margin-left: 30px;
      flex: 1;
      .name {
        font-size: 20px;
        font-family: PingFangMedium;
        font-weight: bold;
        color: #000000;
        line-height: 30px;
        span {
          display: inline-block;
          width: 44px;
          height: 23px;
          text-align: center;
          background: rgba(21, 183, 221, 0.12);
          border-radius: 4px 4px 4px 4px;
          margin-right: 8px;
          font-size: 14px;
          font-family: PingFangMedium;
          font-weight: bold;
          color: #15b7dd;
          line-height: 23px;
        }
      }
      .describe {
        margin-top: 16px;
        font-size: 14px;
        font-family: PingFangMedium;
        font-weight: bold;
        color: #1e97b4;
        line-height: 21px;
      }
      .introduce {
        padding: 24px 16px 0 16px;
        font-size: 16px;
        font-family: PingFangMedium;
        color: #666666;
        line-height: 24px;
      }
      .menu {
        margin-top: 24px;
        padding: 0 16px;
        font-size: 16px;
        font-family: Source Han Sans CN-Normal, Source Han Sans CN;
        color: #333333;
        line-height: 24px;
        display: flex;
        align-items: center;
        img{
          width: 16px;
          margin-right: 5px;
        }
        span {
          font-size: 16px;
          font-family: Source Han Sans CN-Medium, Source Han Sans CN;
          font-weight: 500;
          color: #15B7DD;
          line-height: 24px;
        }
      }
      .classDate {
        margin-left: 16px;
        display: flex;
        .title {
          width: 71px;
          font-size: 14px;
          font-family: PingFangMedium;
          color: #666666;
          line-height: 21px;
        }
        .courseList {
          flex: 1;
          display: flex;
          flex-wrap: wrap;
          .courseItem {
            position: relative;
            padding: 18px;
            box-shadow: 0px 4px 10px 1px rgba(93, 131, 167, 0.15);
            display: flex;
            width: 324px;
            margin-left: 16px;
            margin-bottom: 16px;
            img {
              width: 60px;
              height: 60px;
            }
            .courseDateList {
              width: 624px;
              position: absolute;
              top: 90px;
              left: 0;
              background-color: #ffffff;
              z-index: 5;
              box-shadow: 0px 4px 10px rgba(93, 131, 167, 0.15);
              padding: 26px 16px 20px;
              display: flex;
              flex-wrap: wrap;
              .courseDateItem:nth-child(3n + 2) {
                margin: 0;
              }
              .courseDateItem {
                width: 186px;
                padding: 5px 17px;
                font-size: 14px;
                font-family: PingFangMedium;
                font-weight: 400;
                color: #333333;
                line-height: 28px;
                border-radius: 4px;
                margin-right: 17px;
                border: 1px solid #ebebeb;
                .top {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  .name {
                    width: 85px;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 28px;
                  }
                  .num {
                    span {
                      color: #15b7dd;
                    }
                  }
                }
              }
              .active {
                color: #ffffff;
                background: #15b7dd;
                .top {
                  .name {
                    color: #ffffff;
                  }
                  .num {
                    span {
                      color: #ffffff;
                    }
                  }
                }
              }
            }
            .courseMessage {
              padding-left: 16px;
              .name {
                width: 208px;
                font-size: 14px;
                font-family: PingFangMedium;
                font-weight: 400;
                color: #333333;
                line-height: 21px;
              }
              .findClassDate {
                cursor: pointer;
                float: right;
                margin-top: 21px;
                text-align: right;
                font-size: 12px;
                font-family: PingFangMedium;
                font-weight: 400;
                color: #15b7dd;
                line-height: 18px;
              }
            }
          }
        }
        .classList {
          flex: 1;
          display: flex;
          flex-wrap: wrap;
          .classItem {
            width: 225px;
            background: #ffffff;
            border-radius: 4px;
            border: 1px solid #ebebeb;
            margin-right: 16px;
            margin-bottom: 16px;
            padding: 5px 0;
            text-align: center;
            .classname {
              font-size: 14px;
              font-family: PingFangMedium;
              color: #333333;
              line-height: 28px;
            }
            .time {
              font-size: 14px;
              font-family: PingFangMedium;
              color: #333333;
              line-height: 28px;
            }
          }
          .classItem:nth-child(3n) {
            margin-right: 0;
          }
        }
      }
      .buybutton {
        width: 142px;
        height: 46px;
        background: #15b7dd;
        border-radius: 23px;
        margin-top: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        position: relative;
        overflow: hidden;
        cursor: pointer;
        margin-left: 16px;
        &:hover::before {
          left: 100%;
        }
        p {
          margin-left: 5px;
        }
      }
      .buybutton::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        left: -100%;
        background: linear-gradient(90deg, transparent, hsla(0, 0%, 100%, .5), transparent);
        transition: all .5s;
      }
      .showAll {
        margin-bottom: 24px;
        margin-left: 71px;
        text-align: center;
        font-size: 14px;
        font-family: PingFangMedium;
        color: #999999;
        line-height: 21px;
        span {
          cursor: pointer;
        }
        .icon {
          margin-left: 4px;
        }
      }
      .shopMessage {
        margin-top: 20px;
        padding: 30px 16px;
        background: rgba(21, 183, 221, 0.05);
        position: relative;
        .studyNum {
          position: absolute;
          right: 27px;
          top: 30px;
          .num {
            font-size: 16px;
            font-family: PingFangMedium;
            font-weight: 500;
            color: #15b7dd;
            line-height: 24px;
            text-align: right;
          }
          .font {
            font-size: 14px;
            font-family: PingFangMedium;
            font-weight: 500;
            color: #666666;
            line-height: 21px;
          }
        }
        .title {
          width: 72px;
          font-size: 14px;
          font-family: PingFangMedium;
          color: #666666;
          line-height: 21px;
        }
        .price {
          display: flex;
          align-items: center;
          .new {
            font-size: 16px;
            font-family: PingFangMedium;
            color: #EC6C01;
            line-height: 21px;
            margin-top: -5px;
            span {
              font-size: 28px;
            }
            .new_point {
              font-size: 16px;
            }
          }
          .newFree {
            font-size: 24px;
            margin-top: -9px;
          }
          .old {
            font-size: 16px;
            font-family: PingFangMedium;
            text-decoration: line-through;
            color: #999999;
            line-height: 21px;
            margin-left: 9px;
          }
        }
        .navs {
          display: flex;
          align-items: center;
          margin-top: 24px;
          position: relative;
          .couponList{
            position: absolute;
            z-index: 2;
            top: 26px;
            left: 90px;
            background: #FFFFFF;
            border-radius: 5px;
            box-shadow: 0px 4px 16px 0px rgba(0,0,0,0.1600);
            padding: 30px 20px 16px;
            .coupon{
              width: 322px;
              margin-bottom: 16px;
              padding-right: 15px;
              height: 80px;
              display: flex;
              align-items: center;
              background-image: url(~@/assets/image/knowledgeMall/coupon_1.png);
              background-size: 100% 100%;
              .couponLeft{
                padding-right: 20px;
                width: 93px;
                text-align: center;
                .price{
                  display: flex;
                  justify-content: center;
                  align-items: flex-end;
                  font-size: 16px;
                  font-family: PingFangRegular;
                  font-weight: 300;
                  color: #3A3330;
                  line-height: 37px;
                  span{
                    font-size: 30px;
                    line-height: 45px;
                    font-family: PingFangMedium;
                  }
                }
                .condition{
                  margin-top: -5px;
                  width: 80px;
                  font-size: 10px;
                  font-family: PingFangRegular;
                  font-weight: 400;
                  color: #3A3330;
                  line-height: 15px;
                  transform: scale(0.9);
                }
              }
              .couponRight{
                flex: 1;
                .couponName{
                  margin-left: -10px;
                  font-size: 14px;
                  font-family: Source Han Sans CN-Medium, Source Han Sans CN;
                  font-weight: 500;
                  color: #333333;
                  line-height: 14px;
                }
                .useTime{
                  margin-left: -10px;
                  margin-top: 7px;
                  font-size: 12px;
                  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                  font-weight: 400;
                  color: #999999;
                  line-height: 8px;
                  transform: scale(0.9);
                }
                .scope{
                  margin-top: 8px;
                  font-size: 10px;
                  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                  font-weight: 400;
                  color: #842C17;
                  line-height: 10px;
                }
              }
              .couponBtn{
                cursor: pointer;
                background: linear-gradient(134deg, #FFE4C2 0%, #FFD39F 100%);
              }
              .couponStyle{
                background: linear-gradient(134deg, #FFE4C2 0%, #FFD39F 100%);
              }
              .couponBtn,
              .couponStyle{
                text-align: center;
                width: 60px;
                height: 25px;
                border-radius: 2px;
                font-size: 14px;
                font-family: Source Han Sans CN-Medium, Source Han Sans CN;
                font-weight: 500;
                color: #333333;
                line-height: 25px;
              }
            }
            .coupon:last-child{
              margin-bottom: 0;
            }
            .geted{
              opacity: 0.5;
              // background-image: url(~@/assets/image/knowledgeMall/coupon_2.png);
            }
          }
          .nav {
            font-size: 12px;
            font-family: PingFangMedium;
            color: #EC6C01;
            line-height: 18px;
            border-radius: 4px;
            border: 1px solid #EC6C01;
            padding: 2px 8px;
            font-weight: 400;
            margin-right: 8px;
          }
          .button {
            margin-left: 20px;
            font-size: 14px;
            font-family: PingFangMedium;
            color: #333333;
            line-height: 21px;
            padding-left: 20px;
            border-left: 1px dashed #999999;
            cursor: pointer;
          }
        }
        .giftList {
          display: flex;
          align-items: top;
          margin-top: 24px;
          .giftItem {
            display: flex;

            img {
              width: 40px;
              height: 40px;
            }
            .message {
              width: 68px;
              margin: 0 16px 0 8px;
              .giftName {
                font-size: 14px;
                font-family: PingFangMedium;
                color: #333333;
                line-height: 21px;
              }
              .count {
                margin-top: 5px;
                font-size: 12px;
                font-family: PingFangMedium;
                color: #333333;
                line-height: 18px;
              }
            }
          }
        }
      }
    }
  }
}
.tabs-box {
 margin-top: 46px;
  background-color: #fff;
  padding: 75px 40px 40px;
  margin-bottom: 50px;
  position: relative;
  .tabs {
    height: 56px;
    background-color: #f8f8f8;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    // padding: 0 20px;

    .tabsdiv {
      margin-right: 40px;
      // padding: 10px 20px;
      width: 182px;
      height: 64px;
      font-size: 20px;
      font-family: PingFangMedium;
      position: relative;
      color: #666;
      display: flex;
      align-items: center;
      justify-content: center;
      // transition: all 0.3s;
    }
    .a1 {
        background-image: url("../../assets/image/curriculum/20220607-134028.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100%;  
        // font-size: 24px;
        color: @theme;
        // transition: all 0.3s;
    }
    .a2 {
        background-image: url("../../assets/image/curriculum/20220506-144134.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100%;
        color: #333333;
        // transition: all 0.3s;
          // font-size: 24px;
        color: @theme;
    }
  }
}
</style>
