<template>
  <div class="sidebar" v-if="$store.state.userInfo.userId">
    <div class="shopCar" @click="goShopCar">
      <img class="icon" src="@/assets/image/knowledgeMall/shopCarIcon.png" alt="">
      <p class="text">购物车</p>
      <p class="number">{{shopCarNum>99?'99+':shopCarNum}}</p>
    </div>
    <!-- <div class="servicePeople">
      <img class="icon" src="@/assets/image/knowledgeMall/coupon.png" alt="">
      <div class="couponList">
        <p class="couponTitle">我的优惠券</p>
        <div class="couponItem" v-for="(item,index) in couponList" :key="index">
          <div class="left">
            <p class="couponPrice">￥<span>{{item.couponPrice}}</span></p>
            <p class="condition">{{item.condition}}</p>
          </div>
          <div class="right">
            <p class="couponName">{{item.name}}</p>
            <p class="time">{{item.time}}</p>
            <p class="type">【{{item.type}}】</p>
          </div>
        </div>
      </div>
      <div class="box"></div>
    </div> -->
    <div class="orderIcon" @click="$router.push('/personalCenter/myCoupon')">
      <img class="icon" src="@/assets/image/knowledgeMall/coupon.png" alt="">
      <p class="title">我的优惠券</p>
    </div>
    <div class="orderIcon" @click="$router.push('/personalCenter/MyOrder')">
      <img class="icon" src="@/assets/image/knowledgeMall/orderIcon.png" alt="">
      <p class="title">我的订单</p>
    </div>
    <div class="servicePeople">
      <img class="icon" src="@/assets/image/knowledgeMall/servicePeople.png" alt="">
      <div class="qrCode">
        <img src="@/assets/image/nav/banner_person.png" alt="">
        <p>扫一扫</p>
        <p>添加微信客服</p>
      </div>
      <div class="box"></div>
    </div>
    <div class="backTop" v-if="scrollTop > 1000">
      <a-back-top>
        <img title="返回顶部" src="@/assets/image/knowledgeMall/backTop.png" alt="">
      </a-back-top>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return {
      scrollTop: 0, // 滚动高度
      shopCarNum: 0, // 购物车数量
      couponList: [
        {
          name: '孤独症课程优惠券',
          time: '2022/04/03  23:00～2022/04/03  23:00',
          type: '全场通用',
          couponPrice: '100',
          condition: '满1000可用'
        },
        {
          name: '孤独症课程优惠券',
          time: '2022/04/03  23:00～2022/04/03  23:00',
          type: '全场通用',
          couponPrice: '100',
          condition: '满1000可用'
        },
        {
          name: '孤独症课程优惠券',
          time: '2022/04/03  23:00～2022/04/03  23:00',
          type: '全场通用',
          couponPrice: '100',
          condition: '满1000可用'
        },
        {
          name: '孤独症课程优惠券',
          time: '2022/04/03  23:00～2022/04/03  23:00',
          type: '全场通用',
          couponPrice: '100',
          condition: '满1000可用'
        }
      ]
    }
  },
  created(){
    if(this.$store.state.userInfo.userId){
      this.getShopList();
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll) // 监听页面滚动
  },
  methods:{
    // 跳转购物车
    goShopCar() {
      if(this.shopCarNum === 0){
        if(this.$route.path == "/shopBar"){
          this.$router.push('/knowledgeMall/shopHome');
        }
        return this.$message.error('请添加商品');
      }
      this.$router.push('/shopBar')
    },
    // 返回顶部
    backTop() {
      document.documentElement.scrollTop = 0;
    },
    // 获取页面滚动距离
    handleScroll () {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      this.scrollTop = scrollTop;
    },
    // 获取购物车列表
    getShopList() {
      this.$ajax({
        url: '/hxclass-pc/pc-mall/shop/list',
        method: 'get'
      }).then((res)=>{
        if(res.code == 200 && res.success){
          this.shopCarList = res.data
          let num = 0;
          res.data.map(item=>{
            num += item.count;
          })
          this.shopCarNum = num;
        }
      })
    }
  },
  // 滚动重置
  beforeDestroy () {
      window.removeEventListener('scroll', this.handleScroll)
  },
}
</script>

<style scoped lang="less">
  .sidebar{
    width: 40px;
    height: calc(100vh - 60px);
    position: fixed;
    right: 0;
    top: 60px;
    background: #04728C;
    z-index: 99;
    padding-top: 20vh;
    div{
      text-align: center;
    }
    .backTop{
      width: 40px;
      height: 47px;
      text-align: center;
      position: fixed;
      bottom: 0;
      right: 0;
      img{
        width: 23px;
        height: 23px;
        cursor: pointer;
      }
      .ant-back-top{
        position: initial;
      }
    }
    @media screen and (max-width:16000px) {
      .backTop,.ant-back-top{
        width: 35px;
      }
    }
    .servicePeople{
      height: 31px;
      padding: 7px 0;
      margin-top: 14px;
      position: relative;
      cursor: pointer;
      .icon{
        width: 20px;
        height: 20px;
      }
      .couponList{
        width: 290px;
        height: 450px;
        background: #FFFFFF;
        box-shadow: 0px 4px 10px 0px rgba(93,131,167,0.1500);
        position: absolute;
        left: -290px;
        top: -200px;
        display: none;
        padding: 24px;
        .couponTitle{
          font-size: 18px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 500;
          color: #333333;
          line-height: 18px;
          text-align: left;
        }
        .couponItem{
          display: flex;
          background-image: url('~@/assets/image/knowledgeMall/couponBack.png');
          background-size: 100%;
          background-repeat: no-repeat;
          width: 242px;
          height: 80px;
          margin-top: 16px;
          padding-top: 12px;
          .left{
            width: 92px;
            .couponPrice{
              font-size: 16px;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 300;
              color: #3A3330;
              margin-top: 12px;
              text-align: left;
              span{
                font-size: 30px;
                font-weight: bold;
              }
            }
            .condition{
              font-size: 10px;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 400;
              color: #3A3330;
              line-height: 15px;
              padding-left: 10px;
              text-align: left;
            }
          }
          .right{
            flex: 1;
            padding-top: 3px;
            .couponName{
              font-size: 14px;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 500;
              color: #333333;
              line-height: 14px;
              text-align: left;
              margin-left: -10px;
            }
            .time{
              margin-left: -20px;
              width: 100px;
              font-size: 12px;
              font-family: Source Han Sans CN-Regular, Source Han Sans CN;
              font-weight: 400;
              color: #999999;
              line-height: 8px;
              margin-top: 7px;
              text-align: left;
              white-space: nowrap;
              transform: scale(0.7);
            }
            .type{
              margin-top: 8px;
              font-size: 10px;
              font-family: Source Han Sans CN-Regular, Source Han Sans CN;
              font-weight: 400;
              color: #842C17;
              line-height: 10px;
              text-align: left;
            }
          }
        }
      }
      .qrCode{
        width: 128px;
        height: 178px;
        background: #FFFFFF;
        box-shadow: 0px 4px 10px 0px rgba(93,131,167,0.1500);
        position: absolute;
        left: -128px;
        top: -69px;
        display: none;
        padding: 14px;
        img{
          width: 100px;
          height: 100px;
          margin-bottom: 8px;
        }
        p{
          font-size: 14px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          line-height: 21px;
          text-align: center;
        }
      }
      .box{
        width: 0;
        height: 0;
        border-top: 4px solid transparent;
        border-left: 4px solid #FFFFFF;
        border-bottom: 4px solid transparent;
        position: absolute;
        left: 0px;
        top: 14px;
        display: none;
      }
    }
    .servicePeople:hover{
      background: #15B7DD;
      .qrCode{
        display: block;
      }
      .couponList{
        display: block;
      }
      .box{
        display: block;
      }
    }
    .orderIcon{
      height: 31px;
      padding: 7px 0;
      margin-top: 14px;
      position: relative;
      cursor: pointer;
      .icon{
        width: 20px;
        height: 20px;
      }
      .title{
        width: 92px;
        height: 32px;
        box-sizing: border-box;
        position: absolute;
        left: -0px;
        top: 0;
        border-radius: 3px 0px 0px 3px;
        border: 1px solid #C4C4C4;
        border-right: 0;
        line-height: 29px;
        font-size: 12px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        background: #FFFFFF;
        display: none;
      }
    }
    .orderIcon:hover{
      background: #15B7DD;
      .title{
        display: block;
        left: -92px;
      }
    }
    .shopCar{
      background: #15B7DD;
      padding: 23px 0 12px;
      cursor: pointer;
      .icon{
        width: 20px;
        height: 18px;
      }
      .text{
        width: 15px;
        text-align: center;
        margin: 0 auto;
        padding-top: 8px;
        border-top: 1px solid #FFFFFF;
        margin-top: 8px;
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 17px;
      }
      .number{
        width: 22px;
        height: 22px;
        margin: 0 auto;
        background: #FFFFFF;
        border-radius: 50%;
        font-size: 12px;
        font-family: Arial-Regular, Arial;
        font-weight: 400;
        color: #EC6C01;
        line-height: 22px;
        text-align: center;
        margin-top: 10px;
      }
    }
  }
  @media screen and (max-width: 16000px) {
    .sidebar{
      width: 35px;
    }
  }
</style>